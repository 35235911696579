<!-- 导出水电燃读数记录：weg/exportWaterEleGasList -->
<!-- 停用启用表：weg/toggleStatus -->
<template>
  <div>
    <div class="overflow-scroll">
      <div style="min-width: 1110px">
        <a-card>
          <!-- 头部搜索栏 -->
          <div class="table-operator">
            <a-form layout="inline">
              <a-row :gutter="0">
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号">
                    <a-input
                      style="width: 175px"
                      v-model="queryParam.facilityCode"
                      @pressEnter="selectList"
                      placeholder="请输入"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称">
                    <a-input
                      style="width: 175px"
                      v-model="queryParam.facilityName"
                      @pressEnter="selectList"
                      placeholder="请输入"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="档口编号">
                    <a-input
                      style="width: 175px"
                      v-model="queryParam.kitchenCode"
                      :disabled="queryParam.isCantKitchenCode"
                      @pressEnter="selectList"
                      placeholder="请输入"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row style="margin-top:20px">
                <a-col :md="8" :sm="24">
                  <a-form-item label="属性">
                    <a-select
                      style="width: 175px"
                      v-model="queryParam.useScope"
                      @change="changeUseScope"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in useScopeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="设备类型">
                    <a-select
                      style="width: 175px"
                      v-model="queryParam.deviceType"
                      @change="selectList"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in deviceTypeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="是否智能设备">
                    <a-select
                      style="width: 175px"
                      v-model="queryParam.enableSmart"
                      @change="selectList"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in smartList" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :sm="24" style="text-align: right">
                  <span class="table-page-search-submitButtons">
                    <a-button
                      style="margin-left: 8px"
                      @click="() => ((queryParam = {}), this.init())"
                      >重置</a-button
                    >
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 功能按钮 -->
          <div class="btnBox">
            <div style="display: flex; align-items: center;">
              <a-button type="primary" icon="plus" class="btn-item" @click="addNew">新建</a-button>
              <a-upload
                accept=".xlsx,.xls,.csv"
                withCredentials
                :action="this.uploadUrl()"
                name="multipartFile"
                :showUploadList="false"
                @change="uploadExcel"
              >
                <a-button type="primary" icon="plus" class="btn-item" >批量新建</a-button>
              </a-upload>
              <span class="blueColor btn-item">
                <a
                  href="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/weg_batch_create.xlsx"
                  download="https://css-2019-shanghai.oss-cn-shanghai.aliyuncs.com/weg_batch_create.xlsx"
                  >模版下载</a>
              </span>
            </div>
            <div style="display: flex; align-items: center;">
              <span class="btn-item"><a-button type="primary" @click="showExportExcel">导出水电燃读数</a-button></span>
              <span class="btn-item"><a-button type="primary" @click="onExportWegStatus">导出水电燃表状态</a-button></span>
            </div>
          </div>
          <!-- 表格数据 -->
          <a-spin :spinning="dataLoading">
            <a-table
              rowKey="id"
              :pagination="pagination"
              :columns="columns"
              :dataSource="wegList"
              :scroll="{ x: 2000 }"
              @change="changePageSize"
              style="margin-bottom: 24px"
            >
              <span slot="kitchenCode" slot-scope="text, record">
                <template>
                  <span>{{ record.kitchenCode }}</span>
                </template>
              </span>
              <span slot="type" slot-scope="text, record">
                <template>
                  <span>{{ showType(record.type) }}</span>
                </template>
              </span>
              <span slot="useScope" slot-scope="text, record">
                <template>
                  <span>{{ showUseScope(record.useScope) }}</span>
                </template>
              </span>
              <span slot="action" slot-scope="text, record" class="blueColor">
                <template>
                  <a @click="handleView(record)">查看详情</a>
                  <a @click="changeStatus(record)" style="margin-left:8px;">
                    <span :class="record.isDeleted == 'N' ? 'red' : 'green'">
                      {{ (record.isDeleted == "N" && "停用") || "启用" }}
                    </span>
                  </a>
                  <a v-if="record.kitchenId && record.isDeleted && record.isDeleted == 'N'" @click="onClickChange(record)" style="margin-left:8px;">更换</a>
                </template>
              </span>
            </a-table>
          </a-spin>
          <!-- 导出弹窗 -->
          <a-modal
            title="导出表格"
            :visible="showExportModal"
            :confirmLoading="showExportModalLoading"
            @ok="exportExcel"
            @cancel="cancelExportExcel"
            destroyOnClose
          >
            <a-row :gutter="30" style="line-height: 4">
              <a-col :md="24" :sm="24">
                <span>城市：</span>
                <a-select
                  style="width: 175px;margin-left: 0.5em;"
                  v-model="exportQuery.cityId"
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in userCities" :key="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :md="24" :sm="24">
                <span>设备类型：</span>
                <a-select
                  style="width: 175px"
                  v-model="exportQuery.type"
                  @change="selectList"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in deviceAllTypeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :md="24" :sm="24">
                <span>开始日期：</span>
                <a-date-picker
                  format="YYYY-MM-DD"
                  placeholder="选择开始日期"
                  :disabledDate="disabledStartDate"
                  @change="startDateChange"
                />
              </a-col>
              <a-col :md="24" :sm="24">
                <span>结束日期：</span>
                <a-date-picker
                  :disabledDate="disabledEndDate"
                  format="YYYY-MM-DD"
                  placeholder="选择结束日期"
                  v-model="endValue"
                  :open="endOpen"
                  @change="endDateChange"
                  @openChange="handleEndOpenChange"
                  style="margin-left:8px;"
                />
              </a-col>
              <a-col :md="24" :sm="24">
                <span class="spAl">相差不大于120天</span>
              </a-col>
            </a-row>
          </a-modal>
          <!-- 新建表信息弹窗 -->
          <a-modal
            title="新建"
            :visible="showAddInfo"
            :confirmLoading="showAddInfoLoading"
            @ok="handleSaveAdd"
            @cancel="handleCancelAdd"
            destroyOnClose
          >
            <a-form layout="inline" :form="form">
              <a-row :gutter="30" style="line-height: 4">
                <a-col :md="24" :sm="24">
                  <a-form-item label="所属门店">
                    <a-select
                      @change="getKitchen"
                      style="width: 240px"
                      v-decorator="[
                        'facilityId',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in facilityList"
                        :key="item.id"
                      >
                        {{ item.optionText }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备属性">
                    <a-select
                      @change="addChangeuseScope"
                      style="width: 240px"
                      v-decorator="[
                        'useScope',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in useScopeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-if="this.iskitchen" :md="24" :sm="24">
                  <a-form-item label="所属档口">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'kitchenId',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in kitchList" :key="item.id">
                        {{ item.code }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备类型">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'type',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in deviceTypeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备名称">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'deviceName',
                        {
                          rules: [{ required: true, message: '请输入' }]
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="是否智能设备">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'enableSmart',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in smartList" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="电表号">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'pcode',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请输入！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="所属网关">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'ccode',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请输入！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="倍率">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'ratio',
                        {
                          rules: [
                            {
                              required: true,
                              pattern: /^[0-9]*[1-9][0-9]*$/,
                              message: '请输入正整数！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="初始读数">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'meterNumber',
                        {
                          rules: [
                            {
                              required: true,
                              pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                              message: '请输入初始读数！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="初始读数日期">
                    <a-date-picker
                      v-decorator="[
                        'meterDate',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择初始读数日期！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请选择"
                      format="YYYY-MM-DD"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-modal>
          <!-- 导出水电燃表状态 -->
         <a-modal
            title="导出水电燃表状态"
            :visible="showExportWegStatus"
            :confirmLoading="showExportWegStatusLoading"
            @ok="onConfirmExportWegStatus"
            @cancel="onCancelExportWegStatus"
            destroyOnClose
          >
            <a-row :gutter="30" style="line-height: 4">
              <a-col :md="24" :sm="24">
                <span>城市：</span>
                <a-select
                  style="width: 175px;margin-left: 0.5em;"
                  v-model="exportWegStatusQuery.cityId"
                  placeholder="请选择"
                  @change="onChangeExportWegStatusCity"
                >
                  <a-select-option v-for="item in userCitiesAll" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :md="24" :sm="24">
                <span>门店：</span>
                <a-select
                  style="width: 175px;margin-left: 0.5em;"
                  v-model="exportWegStatusQuery.facilityId"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in exportWegStatusAvailableFacility" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-modal>
          <!-- 更换弹窗 -->
          <a-modal
            title="更换"
            :visible="showChangeInfo"
            :confirmLoading="showChangeInfoLoading"
            @ok="handleSaveChange"
            @cancel="handleCancelChange"
            destroyOnClose
          >
            <a-form layout="inline" :form="changeForm">
              <a-row :gutter="30" style="line-height: 4">
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备名称">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'deviceName',
                        {
                          rules: [{ required: true, message: '请输入' }]
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备类型">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'type',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                      disabled
                    >
                      <a-select-option
                        v-for="item in deviceTypeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="设备属性">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'useScope',
                        {
                          rules: [{ required: true, message: '请选择' }]
                        }
                      ]"
                      placeholder="请选择"
                      disabled
                    >
                      <a-select-option
                        v-for="item in useScopeList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="是否智能设备">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'enableSmart',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in smartList" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="电表号">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'pcode',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请输入！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="所属网关">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'ccode',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请输入！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="倍率">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'ratio',
                        {
                          rules: [
                            {
                              required: true,
                              pattern: /^[0-9]*[1-9][0-9]*$/,
                              message: '请输入正整数！'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="初始读数">
                    <a-input
                      style="width: 240px"
                      v-decorator="[
                        'meterNumber',
                        {
                          rules: [
                            {
                              required: true,
                              pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                              message: '请输入初始读数！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="初始读数日期">
                    <a-date-picker
                      v-decorator="[
                        'meterDate',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择初始读数日期！'
                            }
                          ]
                        }
                      ]"
                      placeholder="请选择"
                      format="YYYY-MM-DD"
                    />
                  </a-form-item>
                </a-col>

                <a-col :md="24" :sm="24">
                  <a-form-item label="抄表渠道">
                    <a-select
                        style="width: 175px;margin-left: 0.5em;"
                        v-decorator="[
                        'channel',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择！'
                            }
                          ]
                        }
                      ]"
                        placeholder="请选择"
                    >
                      <a-select-option v-for="item in channelList" :key="item.id">{{
                          item.name
                        }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

              </a-row>
            </a-form>
          </a-modal>
        </a-card>
      </div>
    </div>
   
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";

export default {
  name: "hydropowerConfigureNewPage",
  components: {},
  data() {
    return {
      //设备类型
      deviceTypeList: [
        { id: 1, name: "水表" },
        { id: 2, name: "电表" },
        { id: 3, name: "燃气表" }
      ],
      //设备类型
      deviceAllTypeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "水表" },
        { id: 2, name: "电表" },
        { id: 3, name: "燃气表" }
      ],
      //属性
      useScopeList: [
        { id: 1, name: "公摊" },
        { id: 2, name: "自用" },
        { id: 3, name: "总表" }
      ],
      // 是否智能设备
      smartList: [
        { id: "Y", name: "是" },
        { id: "N", name: "否" }
      ],
      channelList: [
        { id: 1, name: "正常抄表" },
        { id: 2, name: "离场登记抄表" },
        { id: 3, name: "开业审批抄表" }
      ],
      // 查询的参数
      queryParam: {
        facilityCode: "",
        facilityName: "",
        useScope: undefined,
        kitchenCode: "",
        deviceType: undefined,
        enableSmart: undefined
      },
      // 列表数组
      wegList: [],
      // 分页
      dataLoading: false,
      current: 1,
      pageSize: 20,
      pagination: defaultPagination(() => {}),
      columns: [
        {
          title: "城市",
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "门店编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode"
        },
        {
          title: "门店名称",
          width: 160,
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "属性",
          dataIndex: "useScope",
          key: "useScope",
          scopedSlots: { customRender: "useScope" }
        },
        {
          title: "是否智能设备",
          dataIndex: "enableSmartName",
          key: "enableSmartName",
          width: 140
        },
        {
          title: "电表号",
          dataIndex: "pcode",
          width: 160,
          key: "pcode"
        },
        {
          title: "所属网关",
          dataIndex: "ccode",
          width: 160,
          key: "ccode"
        },
        {
          title: "设备类型",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" }
        },
        {
          title: "倍率",
          dataIndex: "ratio",
          key: "ratio"
        },
        {
          title: "设备名称",
          width: 160,
          dataIndex: "deviceName",
          key: "deviceName"
        },
        {
          title: "水电付费模式",
          dataIndex: "prepayModeName",
          key: "prepayModeName",
          width: 140
        },
        {
          title: "蜂电在线状态",
          dataIndex: "fengdianStatusName",
          key: "fengdianStatusName",
          width: 140
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "action",
          fixed: "right",
          width: 180
        }
      ],
      // 弹窗
      showExportModal: false,
      showExportModalLoading: false,
      endOpen: false,
      exportQuery: {},
      startValue: null,
      endValue: null,
      // 新增弹窗
      showAddInfo: false,
      showAddInfoLoading: false,
      form: this.$form.createForm(this),
      //所属门店
      facilityList: [],
      //档口列表
      kitchList: [],
      // 是否显示档口
      iskitchen: false,
      // 限制天数
      rangeDay: -120,
      // 正在上传中
      uploading: false,

      // 导出水电燃表状态相关
      showExportWegStatus: false,
      showExportWegStatusLoading: false,
      exportWegStatusQuery: {},
      exportWegStatusAvailableFacility: [],
      // 更换弹窗
      showChangeInfo: false,
      showChangeInfoLoading: false,
      changeForm: this.$form.createForm(this),
      currentItem: null
    };
  },
  created() {
    this.init();
    this.getUserCities();
    Bus.$on("getTarget", target => {
      this.init();
    });
    this.getFacility();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    // 设备类型
    showType(value) {
      return function(value) {
        let val = "";
        this.deviceTypeList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },
    // 公摊/自用
    showUseScope(value) {
      return function(value) {
        let val = "";
        this.useScopeList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },

    ...mapGetters("cities", ["userCities", "userCitiesAll"])
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),

    // resetPage 页码重置函数
    resetPage() {
      this.current = 1;
      this.pageSize = 20;
      this.pagination = defaultPagination(() => {});
      this.init();
    },
    //  新建弹窗函数
    // 添加获取
    addChangeuseScope(val) {
      if (val == 3 || val == 1) {
        this.iskitchen = false;
        // 如果查询到的档口为空，清空它们之前的选中
        const {
          form: { setFieldsValue }
        } = this;
        setFieldsValue({
          kitchenId: ""
        });
      }
      if (val == 2) {
        this.iskitchen = true;
      }
    },
    // 所属门店
    getFacility() {
      axios({
        url:   this.$baseUrl + "facility/querySelect",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 根据所在门店获取关联档口
    getKitchen(val) {
      // 如果查询到的档口为空，清空它们之前的选中
      this.kitchList = [];
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        kitchenId: ""
      });
      axios({
        method: "GET",
        url:   this.$baseUrl + "/kitchen/querySelect",
        params: {
          page: 1,
          rows: 500,
          facilityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.kitchList = res.data.rows || [];
        }
      });
    },
    //  显示新建弹窗
    addNew() {
      this.showAddInfo = true;
    },
    // 确认函数
    handleSaveAdd() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.showAddInfoLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.submitSaveInfo(values);
        } else {
          this.showAddInfoLoading = false;
        }
      });
    },
    submitSaveBefore(val){
      const { type, useScope, facilityId, kitchenId } = val
      axios({
        method: "POST",
        url:   this.$baseUrl + "ss/Ops/change-weg-check",
        headers: {
          Accept: "*/*"
        },
        data: {
          type, 
          useScope, 
          facilityId, 
          kitchenId 
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            if(res.data.obj){
              this.$message.error(`${res.data.obj}`);
            }else{
              this.submitSaveInfo(val)
            }
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.$message.error(error);
          this.showAddInfoLoading = false;
        });
    },
    // 提交函数
    submitSaveInfo(val) {
      // if (val.enableSmart == "Y" && (!val.pcode || !ccode)) {
      //   this.$message.error(`智能电表,电表号与所属网管有误！`);
      //   this.showAddInfoLoading = false;
      //   retrun;
      // }
      axios({
        method: "POST",
        url:   this.$baseUrl + "/weg/save",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val
        }
      })
          .then(res => {
            if (res.data.returnCode == "200") {
              this.resetPage();
              this.$message.success("新建成功！");
              this.showAddInfo = false;
            } else {
              this.$message.error(`${res.data.returnMsg}`);
            }
            this.showAddInfoLoading = false;
          })
          .catch(error => {
            this.$message.error(error);
            this.showAddInfoLoading = false;
          });
    },
    // 取消弹窗
    handleCancelAdd() {
      // 取消弹窗接口
      this.showAddInfo = false;
    },
    // 换页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init();
    },
    // 列表接口
    init() {
      this.dataLoading = true;
      axios({
        url: this.$endpoints.UTILITY_LIST_WEG_METER_STATUS,
        method: "GET",
        params: {
          rows: this.pageSize || 20,
          page: this.current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.wegList = res.data.rows || [];
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // 改变属性
    changeUseScope() {
      if (this.queryParam.useScope == 3) {
        this.queryParam.kitchenCode = "";
        this.queryParam.isCantKitchenCode = true;
      } else {
        this.queryParam.isCantKitchenCode = false;
      }
      this.selectList();
    },
    // 搜索
    selectList() {
      this.resetPage();
    },
    //编辑
    handleView(val) {
      this.$router.push({
        path:
          "/basicConfiguration/hydropowerConfigure/hydropowerConfigureDetail",
        query: { id: val.id }
      });
    },
    // 改变状态
    changeStatus(val) {
      let id = val.id;
      let isDeleted = val.isDeleted == "Y" ? "N" : "Y";
      // 停用启用表：weg/toggleStatus
      axios({
        method: "POST",
        url:   this.$baseUrl + "weg/toggleStatus",
        data: {
          id: id,
          isDeleted: isDeleted
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.init();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 表格导出
    // 禁止开始时间
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue > endValue;
    },
    // 禁止结束时间
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.valueOf() < startValue.valueOf();
    },
    startDateChange(date, dateString) {
      this.startValue = date;
      this.testInfo();
    },
    endDateChange(date, dateString) {
      this.endValue = date;
      this.testInfo();
    },
    testInfo() {
      if (!this.startValue || !this.endValue) {
        return false;
      }
      let day = this.startValue.diff(this.endValue, "day");
      if (day <= this.rangeDay) {
        this.$message.error(`请重新选择，时间段超出120天！`);
        this.endValue = null;
        this.endOpen = true;
        return;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // showExportExcel 导出弹窗
    showExportExcel() {
      // 还原设置
      this.endOpen = false;
      this.exportQuery = {};
      this.startValue = null;
      this.endValue = null;
      // 弹出
      this.showExportModal = true;
    },
    // 空数据验证
    isCanGetExcel(data) {
      let key = true;
      for (let k in data) {
        let c = typeof data[k] == "number" && !data[k];
        if (!data[k] && !c) {
          key = false;
          break;
        }
      }
      return key;
    },
    // 点击下载
    exportExcel() {
      let data = Object.assign(
        {
          gmtCreatedBegin: this.startValue
            ? this.startValue.format("YYYY-MM-DD")
            : null,
          gmtCreatedEnd: this.endValue
            ? this.endValue.format("YYYY-MM-DD")
            : null
        },
        this.exportQuery
      );
      this.showExportModalLoading = true;
      // 数据验证
      if (this.isCanGetExcel(data)) {
        this.downloadTemplate(
          data,
          "weg/exportWaterEleGasList",
          "水电燃读数记录"
        );
        setTimeout(() => {
          this.showExportModal = false;
          this.showExportModalLoading = false;
        }, 1000);
      } else {
        this.$message.success("导出条件有误！");
        this.showExportModalLoading = false;
      }
    },
    // 导出函数
    downloadTemplate(info, url, name) {
      this.$http.get(url, { 
        params: info,
        responseType: "blob",
      })
      .then(res => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xls";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },
    // 导出函数，使用http POST
    downloadTemplateWithPost(info, url, name) {
      this.$http.post(url, info,{
        responseType: "blob",
      })
      .then(res => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDDHHmmss') + ".xlsx";
          // let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },
    // 取消
    cancelExportExcel() {
      this.showExportModal = false;
    },
    // 批量新建 - Excel上传
    uploadExcel({ fileList }) {
      this.uploading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            if (element.response.returnCode == "200") {
              this.afterUploadExcel(element);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              this.uploading = false;
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // 上传之后，调用后台api
    afterUploadExcel(info) {
      axios({
        url:   this.$baseUrl + "weg/batchCreate",
        method: "POST",
        data: {
          attachmentName: info.name,
          url: info.response.obj,
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$message.success(`${res.data.returnMsg}`);
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.uploading = false;
        })
        .catch(error => {
          this.uploading = false;
          this.$message.error(error.message);
        });
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    onExportWegStatus() {
      this.showExportWegStatus = true;
    },
    onConfirmExportWegStatus() {
      if (this.exportWegStatusQuery.cityId === undefined || 
          this.exportWegStatusQuery.cityId === null) {
        this.$message.error("请选择城市", 3);
        return;
      };

      this.showExportWegStatusLoading = true;

      const queryData = {
        cityIdList: this.getCityIdList(this.exportWegStatusQuery.cityId),
        facilityIdList: this.exportWegStatusQuery.facilityId ? [this.exportWegStatusQuery.facilityId] : null,
      }

      this.downloadTemplateWithPost(
        queryData,
        this.$endpoints.UTILITY_EXPORT_WEG_METER_STATUS,
        "水电燃读数表详情"
      );
      setTimeout(() => {
        this.showExportWegStatusLoading = false;
        this.showExportWegStatus = false;
      }, 1000);
    },
    onCancelExportWegStatus() {
      this.showExportWegStatus = false;
      this.showExportWegStatusLoading = false;
    },
    onChangeExportWegStatusCity() {
      if (this.exportWegStatusQuery.cityId === undefined ||
          this.exportWegStatusQuery.cityId === null) {
        his.exportWegStatusAvailableFacility = [];
      }
      else {
        let cityIds = this.getCityIdList(this.exportWegStatusQuery.cityId);

        axios({
          url: this.$endpoints.FACILITY_LIST_FACILITY_BY_CITY_AND_BRAND,
          method: "GET",
          params: {
            cityIds: cityIds.join(","),
          }
        })
        .then(res => {
          if (res.data.success) {
            this.exportWegStatusAvailableFacility = 
              res.data.rows.map(e => {
                return {
                  "id": e.id,
                  "name": e.optionText
                };
              });
          }
          else {
            this.$message.error("获取所选城市对应门店失败，错误信息：" + res.data.returnMsg, 3);
            this.exportWegStatusAvailableFacility = [];
          }
        })
        .catch(error => {
          this.$message.error("获取所选城市对应门店出错，错误信息：" + error.message, 3);
          this.exportWegStatusAvailableFacility = [];
        });
      }
    },

    getCityIdList(cityId) {
      let cityIds = [];
      if (cityId == 0) {
        cityIds = this.userCities.map(e => e.id);
      }
      else {
        cityIds = [cityId];
      }

      return cityIds;
    },

    // 点击更换
    onClickChange(info){
      this.currentItem = info;
      this.showChangeInfo = true;
      this.$nextTick(()=>{
        this.changeForm.setFieldsValue({
          oldWegId:info.id,
          type: info.type,
          useScope: info.useScope,
        });
      })
      
    },
    // 更换确认函数
    handleSaveChange() {
      let that = this;
      const {
        changeForm: { validateFields }
      } = this;
      this.showChangeInfoLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.submitChangeInfo(values);
        } else {
          this.showChangeInfoLoading = false;
        }
      });
    },
    // 更换提交函数
    submitChangeInfo(val) {
      const { facilityId, kitchenId, id } = this.currentItem
      axios({
        method: "POST",
        url: this.$baseUrl + "weg/change-water-ele-gas",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val,
          facilityId,
          kitchenId,
          oldWegId: id
        }
      })
      .then(res => {
        if (res.data.returnCode == "200") {
          this.resetPage();
          this.$message.success("更换成功！");
          this.showChangeInfo = false;
          this.currentItem = null;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.showChangeInfoLoading = false;
      })
      .catch(error => {
        this.$message.error(error);
        this.showChangeInfoLoading = false;
      });
    },
    // 更换取消弹窗
    handleCancelChange() {
      this.showChangeInfo = false;
      this.showChangeInfoLoading = false;
    },
  }
};
</script>

<style scoped>
.btnBox {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.btn-item {
  margin-right: 16px;
}
.spAl {
  font-size: 0.8em;
  margin-left: 8px;
  color: #aaa;
}
</style>
